@import "@styles/mixins";

.header-section {
    z-index: 1000;
    position: sticky;
    top: 0;

    &.sticky {
        background-color: var(--alternative-color);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        .navbar {
            padding: 1rem 1.5rem;
            
            @include media-min-lg {
                padding: 1rem 6rem ;

                .brand-logo {
                    height: 60px;
                    width: auto;
                }
            }
            
        }
    }

    .brand-logo {
        height: 55px;
        @include media-min-lg {
            height: 100px;
            transition: 300ms height ease-in-out;
        }
    }
    .navbar-toggler-icon {
        width: 2.5rem;
        height: 2.5rem;
        background: none;
        background-size: 100%;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }

    .navbar {
        background-color: var(--alternative-color) !important;
        transition: 300ms padding ease-in-out;
        padding: 1.5rem;
        @include media-min-lg {
            padding: 6rem;
        }
    }

    .navbar-dark .navbar-toggler {
        color: #fff;
        border-color: var(--alternative-color);
    }

    .navbar .navbar-nav .nav-link {
        color: var(--secondary-color);
        font-weight: 600;
        font-size: 1.2rem;
        padding-right: 0.7rem;
        padding-left: 0.7rem;
        line-height: 1.5;

        &:hover, &:active, &:focus {
            color: var(--secondary-darkiest-color)
        }
    }
}