@mixin media-min-sm {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin media-min-md {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin media-min-lg {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin media-min-xl {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin media-min-2xl {
    @media (min-width: 1400px) {
        @content;
    }
}

@mixin media-min-3xl {
    @media (min-width: 1600px) {
        @content;
    }
}

@mixin media-max-sm {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin media-min-before-sm {
    @media (min-width: 577px) {
        @content;
    }
}

@mixin media-min-before-md {
    @media (min-width: 769px) {
        @content;
    }
}

@mixin media-min-before-lg {
    @media (min-width: 993px) {
        @content;
    }
}

@mixin media-min-before-xl {
    @media (min-width: 1201px) {
        @content;
    }
}

@mixin media-max-sm {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin media-max-md {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin media-max-lg {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin media-max-xl {
    @media (max-width: 1200px) {
        @content;
    }
}