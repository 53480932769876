@import "@styles/mixins";

.footer-section {
    padding: 5rem 0;
    background: linear-gradient(to right, var(--primary-color),  var(--secondary-color));

    .img-fluid {
        max-width: 300px;

        @include media-min-sm {
            max-width: 175px;
        }
    }

    .lead {
        font-size: 2.4rem;
        font-weight: 900;
        margin-bottom: 2rem;
        font-family: var(--secondary-font-family);

        @include media-max-sm {
            margin: 0 3rem 2rem;
        }
    }

    .text-center {
        font-size: 1.3rem;
    }

    .input-group {
        padding: 0 2.5rem;

        @include media-max-sm {
            padding: 0;
            flex-direction: column;
        }

        .form-control {
            border-color: #fff;
            border-radius: 0;
            padding: 1.5rem 2rem;
            height: 4rem;
            font-size: 1.2rem;
            color: #fff;

            @include media-max-sm {
                padding: 3rem 2rem;
                width: 100%;
                text-align: center;
                margin-right: 0 !important;
            }

            &::placeholder {
                color: #fff;
            }
        }

        .btn {
            padding: 0.7rem 1.5rem;
            border-radius: 0;
            font-weight: 600;

            @include media-max-sm {
                margin-top: -1px; 
                margin-left: 0 !important;
            }
        }
    }

    .social-links {
        text-align: left;
        img {
            width: 60px;
            aspect-ratio: 1;
            object-fit: cover;
        }
    }

    .term-links {
        li {
            margin-top: 0.5rem;
            a {
                font-weight: 700 !important;
            }
        }
    }

    .alert {
        margin: 0 2.5rem;
        
        p:last-child {
            margin-bottom: 0;
        }
    }
}
