@import "@styles/mixins";

.error-page {
    font-family: var(--secondary-font-family);
    background-color: #ecf0f1;
    background-size: 100% !important;
    background-position: 0% 48%;
    background-repeat: no-repeat;
    height: 100%;

    p {
      font-family: var(--font-family);
    }

    &-content {
      color: var(--alternative-color);
      max-width: 600px;
      margin: 0 auto;
      padding-top: 3rem;
      height: 900px;

      @include media-min-sm {
        padding-top: 5rem;
        height: 1500px;
      }
      
      h1 {
        font-size: 7rem;
        font-weight: 900;
        margin-bottom: 1rem;
        text-align: center;
        
        @include media-min-sm {
          font-size: 13rem;
        }
      }
  
      h2 {
        text-align: center;
        font-size: 3rem;
        font-weight: 900;
        margin-bottom: 3rem;

        @include media-min-sm {
          font-size: 6rem;
        }
      }
  
      p {
        font-size: 1.6rem;
        line-height: 1.3;
        margin-bottom: 2rem;

        @include media-min-sm {
          font-size: 1.8rem;
        }
      }
    }

    &-actions {
      display: flex;
      justify-content: center;
      background-color: var(--alternative-color);
      color: #fff;
      padding: 5rem 0;

      @include media-min-sm {
        padding: 10rem 0;
      }

      .btn-custom {
        margin-bottom: 2rem;
      }

      .px-5 {
        p {
          max-width: 450px;
        }
      }

      p {
        text-align: center;
        font-size: 1.8rem;
      }

      .last-message {
        margin-top: 6rem;
      }
    }
}