@import "./variables.scss";
@import "./mixins.scss";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "bootstrap/scss/bootstrap";

@import url('../fonts/web-fonts.css');

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  color: var(--text-default-color);
  background-color: var(--bg-color);
  line-height: 1;
  background-image: url('../images/Grad1.png');
  background-repeat: no-repeat;
  background-size: 2000px;
  background-position: 69% -180px;

  @include media-min-sm {
    line-height: 1.25;
    background-position: -200px -180px;

    main {
      min-height: calc(100vh - 302px - 412px);
    }
  }

  font: {
    family: var(--font-family);
    size: var(--font-size);
  }

  [data-show-auth] {
    display: none;
  }

  &[data-auth="true"] {
    [data-show-auth="true"] {
      display: block;
    }
  }

  &[data-auth="false"] {
    [data-show-auth="false"] {
      display: block;
    }
  }

  [data-show-dev] {
    display: none !important;
  }

  &[data-env="development"], &[data-env="uat"] {
    [data-show-dev="inline-block"] {
      display: inline-block !important;
    }
    [data-show-dev="block"] {
      display: block !important;
      ;
    }
  }

  [data-show-prod] {
    display: none !important;
  }

  &[data-env="production"] {
    [data-show-prod="inline-block"] {
      display: inline-block !important;
    }
    [data-show-prod="block"] {
      display: block !important;
    }
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    
    > main {
      flex: 1;

      > .maxout-container {
        height: 100%;
        z-index: 1;
        position: relative;
      }

      > .card {
        margin-top: 50px;
        box-shadow: none !important;
      }
    }
  }

  @for $i from 0 through 10 {
    .gap-#{$i} {
      gap: #{$i * 0.5}rem;
    }

    .gap-sm-#{$i} {
      @include media-min-sm {
        gap: #{$i * 0.5}rem;
      }
    }

    .gap-md-#{$i} {
      @include media-min-md {
        gap: #{$i * 0.5}rem;
      }
    }

    .gap-lg-#{$i} {
      @include media-min-lg {
        gap: #{$i * 0.5}rem;
      }
    }

    .gap-xl-#{$i} {
      @include media-min-xl {
        gap: #{$i * 0.5}rem;
      }
    }
  }

  img {
    fill: var(--primary-color)
  }

  p {
      margin-bottom: 15px;
  }

  a {
      color: var(--primary-color);
      transition: var(--transition);

      &:hover {
          color: var(--primary-lighten-color);
      }
  }

  input, select, optgroup, textarea {
    border: 1px solid #e3e6ef;
    padding: 3px 5px;
    border-radius: 0.42rem;
  }

  [type="number"] {
    &::-webkit-inner-spin-button, 
    &::-webkit-outer-spin-button {
        appearance: none;
        display: none;
    }
  }

  .onboarding-title-text-colour {
    color: var(--onboarding-title-text-colour);
  }
  
  .onboarding-title-highlight-colour {
    color: var(--onboarding-title-highlight-colour);
  }

  .onboarding-intro-text-colour {
    color: var(--onboarding-intro-text-colour);
  }

  .onboarding-intro-highlight-colour {
    color: var(--onboarding-intro-highlight-colour);
  }

  .text-xs {
    font-size: 0.7rem;
  }

  .text-sm {
    font-size: 0.9rem;
  }

  .text-md {
    font-size: 1rem;
  }

  .text-lg {
    font-size: 2rem;
  }

  .text-xl {
    font-size: 3rem;
  }

  .text-xxl {
    font-size: 4rem;
  }

  .text-xxxl {
    font-size: 4rem;
  }

  @include media-min-sm {
    .text-sm-xs {
      font-size: 0.7rem;
    }
  
    .text-sm-sm {
      font-size: 0.9rem;
    }
  
    .text-sm-md {
      font-size: 1rem;
    }
  
    .text-sm-lg {
      font-size: 2rem;
    }
  
    .text-sm-xl {
      font-size: 3rem;
    }
  
    .text-sm-xxl {
      font-size: 4rem;
    }
  
    .text-sm-xxxl {
      font-size: 4rem;
    }
  }

  @include media-min-md {
    .text-md-xs {
      font-size: 0.7rem;
    }
  
    .text-md-sm {
      font-size: 0.9rem;
    }
  
    .text-md-md {
      font-size: 1rem;
    }
  
    .text-md-lg {
      font-size: 2rem;
    }
  
    .text-md-xl {
      font-size: 3rem;
    }
  
    .text-md-xxl {
      font-size: 4rem;
    }
  
    .text-md-xxxl {
      font-size: 4rem;
    }
  }

  @include media-max-sm {
    .text-max-sm-xs {
      font-size: 0.7rem;
    }
  
    .text-max-sm-sm {
      font-size: 0.9rem;
    }
  
    .text-max-sm-md {
      font-size: 1rem;
    }
  
    .text-max-sm-lg {
      font-size: 2rem;
    }
  
    .text-max-sm-xl {
      font-size: 3rem;
    }
  
    .text-max-sm-xxl {
      font-size: 4rem;
    }
  
    .text-max-sm-xxxl {
      font-size: 4rem;
    }
  }

  .text-bold-light {
    font-weight: 400;
  }

  .text-bold {
    font-weight: 500;
  }

  .text-bolder {
    font-weight: 600;
  }

  .text-boldest {
    font-weight: 700;
  }

  .text-boldier {
    font-weight: 800;
  }

  .text-boldiest {
    font-weight: 900;
  }

  .text-muted-primary {
    color: var(--primary-lighten-color);
  }

  .text-muted-secondary {
    color: var(--secondary-lighten-color);
  }

  .text-underline {
    text-decoration: underline;
  }

  .text-custom-secondary-title {
    filter: drop-shadow(2px 2px 10px #00000042);
    font-weight: 600;
    color: var(--secondary-text-color);
    margin-bottom: 49px;

    @include media-max-sm {
      font-size: 35px;
    }
  }

  .text-custom-secondary {
    font-size: 16px;
    color: var(--secondary-text-color);
    margin-bottom: 49px;
    white-space: pre-wrap;
  }

  
  .text-primary {
    color: var(--text-highlight-color) !important;
  }
  
  .text-secondary {
    color: var(--secondary-color) !important;
  }
  
  .text-default {
    color: var(--text-default-color) !important;
  }

  .bg-transparent {
    background-color: transparent !important;
  }
  
  .bg-custom-primary {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--secondary-color);

    @include media-min-sm {
      padding-top: 100px;
      padding-bottom: 150px;
    }
  }

  .bg-custom-secondary {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--secondary-color);

    @include media-min-sm {
      padding-top: 100px;
      padding-bottom: 150px;
    }
  }

  .bg-onboarding {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--onboarding-bg-color);
    z-index: 1;
    position: relative;

    @include media-min-sm {
      padding-top: 100px;
      padding-bottom: 150px;
    }
  }

  .bg-primary {
    background-color: var(--primary-color) !important ;
  }

  .bg-primary-darkiest {
    background-color: var(--primary-darkiest-color);
  }

  .bg-secondary {
    background-color: var(--secondary-color) !important;
  }

  .header-title {
    color: var(--header-text-color);
    background-color: var(--header-bg-color);
  }

  .bg-auth {
    font-family: var(--secondary-font-family);
    background-color: #f9f9f9;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='744' height='744' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23EEEEEE' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23FCFCFC'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
  }

  .prz-1 {
    position: relative;
    z-index: 1;
  }

  @include media-min-sm {
    .w-sm-0 {
      width: 0% !important;
    }
    .w-sm-25 {
      width: 25% !important;
    }
    .w-sm-50 {
      width: 50% !important;
    }
    .w-sm-75 {
      width: 75% !important;
    }
    .w-sm-100 {
      width: 100% !important;
    }
  }

  @include media-min-lg {
    .w-lg-0 {
      width: 0% !important;
    }
    .w-lg-25 {
      width: 25% !important;
    }
    .w-lg-50 {
      width: 50% !important;
    }
    .w-lg-75 {
      width: 75% !important;
    }
    .w-lg-100 {
      width: 100% !important;
    }
  }

  @include media-min-xl {
    .w-xl-0 {
      width: 0% !important;
    }
    .w-xl-25 {
      width: 25% !important;
    }
    .w-xl-50 {
      width: 50% !important;
    }
    .w-xl-75 {
      width: 75% !important;
    }
    .w-xl-100 {
      width: 100% !important;
    }
  }

  .field-hide-length {
    .form-description {
      .ml-auto {
        display: none;
      }
    }
  }

  .alert {
    &.alert-primary {
      color: var(--primary-color);
      background-color: var(--primary-lightiest-color);
      border-color: var(--primary-darken-color);
    }
    &.alert-secondary {
      color: var(--secondary-color);
      background-color: var(--secondary-lightiest-color);
      border-color: var(--secondary-darken-color);
    }
  }
  .dropdown {

    .dropdown-toggle.no-psuedo {
      &::after, &::before {
        content: none;
      }
    }

    .dropdown-item:hover, .dropdown-item:focus {
      color: var(--primary-text-color);
      background-color: var(--primary-color);
    }
  }

  .btn {
    padding: 0.3rem 2rem;
    font-weight: 500;
    font-size: 1.3rem;
    border-radius: 0;

    &.btn-secondary {
      background: var(--secondary-darken-color);
      color: #fff;
      border-color: var(--secondary-darken-color);
    }
  }

  .btn.btn-custom {
    font-weight: 900;
    color: #fff;
    padding: 0.6rem 2.5rem;
    font-size: 1.8rem;

    @include media-min-sm {
      padding: 0.6rem 3rem;
      font-size: 2.5rem;
    }
  }

  .jumbo-text {
    line-height: 1;
    font-size: 3.2rem;
    font-weight: 800;
    margin-bottom: 1rem;
    font-family: var(--secondary-font-family);

    @include media-min-sm {
      font-size: 4rem;
    }

    @include media-min-md {
      font-size: 6.25rem;
    }
  }

  @media (min-width: 1350px) {
    .container, .container-2xl {
        max-width: 1200px;
    }
  }

  @media (min-width: 1600px) {
    .container, .container-2xl {
        max-width: 1550px;
    }
  }

  .mb-6 {
    margin-bottom: 6rem;

    @include media-min-sm {
      margin-bottom: 6rem;
    }

    @include media-min-md {
      margin-bottom: 6rem;
    }

    @include media-min-lg {
      margin-bottom: 6rem;
    }

    @include media-min-xl {
      margin-bottom: 6rem;
    }
  }

  *:not(.image-parent):not(.btn):not(.page-loader-content):not(.loader-content) > .spinner-border {
    color: var(--white-color) !important;
  }
  
  *:not(.image-parent):not(.btn) > .spinner-border {
    padding: 20px !important;
  }

  .ellipsis-200 {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: pre;
  }

  .file-placeholder {
    font-size: 0.8rem;
  }

  .table>tbody>tr.success>td, .table>tbody>tr.success>th, .table>tbody>tr>td.success, .table>tbody>tr>th.success, .table>tfoot>tr.success>td, .table>tfoot>tr.success>th, .table>tfoot>tr>td.success, .table>tfoot>tr>th.success, .table>thead>tr.success>td, .table>thead>tr.success>th, .table>thead>tr>td.success, .table>thead>tr>th.success {
    background-color: #dff0d8;
  }

  .table {
    color: inherit;

    tr.no-action td {
      padding: 0.95em 0.75rem;
    }
  }

  .file-group {
    min-height: 75px;
    border-color: #cfd4db;

    .file-placeholder {
      color: #adb5bd;
    }

    .file-preview-group .file-preview-thumbnail {
      height: 75px;
    }

    .loader-spinner {
      .loader-content {
        .spinner-border {
          padding: 0 !important;
          width: 1rem;
          height: 1rem;
          border-width: 2px;
        }
        .my-2 {
          display: none;
        }
        .text-center {
          margin-top: 10px;
          font-size: 10px;
        }
      }
    }
}

  .form-group {
    &.hide-length .form-description .ml-auto {
      display: none; 
    }
  }

  .form-control {
    @include media-max-sm {
      font-size: 0.8rem;
    }

    &.inverse {
      &:focus {
        border-color: #fff !important;
      }
    }
  }

  fieldset {
    -webkit-margin-start: 2px;
    margin-inline-start: 2px;
    -webkit-margin-end: 2px;
    margin-inline-end: 2px;
    padding: 15px;
    border: 1px solid #e3e6ef;

    legend {
      width: auto;
      display: inline-block;
    }
  }

  .input-group {
    flex-wrap: nowrap;

    .react-datepicker-wrapper:not(:last-child) {
      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    
  }

  .custom-control {

    &:not(.custom-switch) {
      .custom-control-label {
        padding-left: 1rem;
  
        &::after, &::before {
          top: 0;
          width: 1.5rem;
          height: 1.5rem;
        }
    
        &::before {
          border: 1px solid var(--primary-darken-color);
        }
      }
    }

    &.custom-switch {
      .custom-control-label {
        padding-left: 10px;

        &::before {
          top: 2px;
          width: 2.5rem;
          height: 1.3rem;
          border-radius: 1.5rem;
        }
        &::after {
          top: 2px;
          left: calc(-2.3rem);
          width: 1.3rem;
          height: 1.3rem;
          border-radius: 1.5rem;
          box-shadow: 0 0px 1px #0000006e;
        }
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #fff;
        transform: translateX(1.3rem);
      }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: var(--primary-color);
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        background-color: var(--primary-color);
      }
    }
  }

  .pagination-lg .page-link {
    padding: .4rem .6rem;
  }

  .text-muted {
    color: #b5b5c3!important;
  }

  .badge {
    padding: 0.4rem 0.6rem;

    &-primary {
      background-color: var(--primary-color);
      color: var(--primary-text-color);
    }

    &-secondary {
      background-color: var(--secondary-color);
      color: var(--secondary-text-color);
    }
  }
  
  .form-control:not(textarea) {
    padding: 10px 15px;
    height: 38px;
    border: 1px solid #e3e6ef;

    &::placeholder {
      color: #b6b6c4;
    }

    &:focus {
      border-color: #9a9eaf;
      box-shadow: none;
    }
  }

  .breadcrumb {
    background-color: transparent;
    padding: 15px 0;
    margin-bottom: 0;
  }

  #retoast-top, #retoast-top-left, #retoast-top-right {
    @include media-max-sm {
      top: 15px;
    }
  }

  .react-datepicker .react-datepicker__header {
    background-color: var(--primary-color);
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, 
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color:var(--primary-color) !important;
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, 
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    border-top-color:var(--primary-color) !important;
  }
  .react-datepicker__day--keyboard-selected, 
  .react-datepicker__month-text--keyboard-selected, 
  .react-datepicker__quarter-text--keyboard-selected, 
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker .react-datepicker__day--selected, 
  .react-datepicker .react-datepicker__day--in-selecting-range,
  .react-datepicker .react-datepicker__day--in-range,
  .react-datepicker .react-datepicker__month-text--selected,
  .react-datepicker .react-datepicker__month-text--in-selecting-range,
  .react-datepicker .react-datepicker__month-text--in-range,
  .react-datepicker .react-datepicker__quarter-text--selected,
  .react-datepicker .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker .react-datepicker__quarter-text--in-range,
  .react-datepicker .react-datepicker__year-text--selected,
  .react-datepicker .react-datepicker__year-text--in-selecting-range,
  .react-datepicker .react-datepicker__year-text--in-range,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color:var(--primary-color);
    color: #fff;
  }
  .react-datepicker__close-icon {
      &:hover::after {
        color: #999;
      }

      &::after {
        cursor: pointer;
        background-color: transparent;
        color: #ccc;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 600;
      }
  }

  .grid-screen:not(.stripped), .form-screen, .card:not(.stripped) {
    &.card {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      // margin-top: 30px;
      border: 0;
      box-shadow: 0px 5px 20px 0px #f0f0f0;

      .card-header {
        padding: 20px !important;

        .card-title {
          display: flex;
          align-items: center;
          color: #181C32;
          font-size: 2em;
          margin-bottom: 0;

          i {
            width: 24px;
            margin-right: 15px;
            font-size: 24px;
            color: #181C32;
          }
        }

        h1 {
          margin: 0 !important;
          font-size: 20px !important;
        }
      }

      .card-footer {
        background-color: #fff;
        padding: 15px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }

  }

  .modal {
    .modal-header, .modal-body, .modal-footer {
      padding: 1.5rem;
    }
    
    .modal-header {
      color: #fff;
      background: linear-gradient(to right, var(--primary-color),  var(--secondary-color));
      .help-text-trigger {
        display: none;
      }

      .close.other-action {
        margin: -1rem -1rem -1rem auto !important;

        + .close {
          margin-left: 0;
        }
      }
    }
    
    .modal-content {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      .form-control {
        padding: 1.5rem 2rem;
        border-radius: 0;
      }
    }

    &.modal-alert {
      .modal-header {
        display: none;
      }
      .modal-body {
        position: static;
        padding-top: 30px;
        padding-bottom: 0;
      }
      .modal-footer {
        border-top: 0 !important;
        justify-content: center;
      }

      .modal-content {
        border-radius: 10px;
        border-color: var(--primary-color);
        clip-path: polygon(30px 0%, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0% 30px);
      }
    }
  }

  .modal-backdrop ~ .modal-backdrop{
    z-index: 1050;
  }

  .grid-table {
    white-space: nowrap;
    .grid-filter {
      margin-bottom: 0;
    }

    .table-hover tbody tr:hover {
      color: var(--primary-darken-color);
    }

    .grid-content table td .btn:hover {
      color: var(--primary-text-color);
    }
    
    .grid-footer {
      .grid-pageinfo {

        @include media-max-sm {
          :not(.grid-page-indicator) {
            display: none;
          }
        }

        .btn {
          font-size: 12px;
          padding: .4rem .6rem;

          &:hover {
            color: var(--primary-text-color);
            background-color: var(--primary-color) !important;
          }
        }

        .dropdown .dropdown-toggle {
          border: 0;
  
          &:hover, &:focus {
            color: var(--primary-text-color);
            background-color: var(--primary-color) !important;
          }
        }

        .dropdown-item.active, .dropdown-item:active {
          color: var(--primary-text-color);
          background-color: var(--primary-color) !important;
        }

      }
      .pagination {
        .page-item .page-link {
          border: 0;
          font-family: var(--secondary-font-family);
          height: auto;
        }

        .page-item.active .page-link, .page-item .page-link:hover {
          color: var(--primary-text-color);
          background-color: var(--primary-color) !important;
          border-color: transparent;
        }

        @include media-max-sm {
          .page-item:not(:first-child):not(:nth-child(2)):not(:last-child):not(:nth-last-child(2)) {
            display: none;
          }

          .page-item:first-child,
          .page-item:nth-child(2),
          .page-item:last-child,
          .page-item:nth-last-child(2) {
            [aria-hidden="true"] {
              display: none !important;
            }

            .sr-only {
              display: block !important;
              position: static;
              width: auto;
              height: auto;
              text-transform: uppercase;
              font-weight: 500;
            }
          }
        }
      }
    }

    &.futuristic-card {
      display: flex;
      flex-direction: column;
      min-height: 450px;
      font-weight: 400;

      &.grid-thin {

        .grid-content.overflow-x-auto {
          overflow-x: unset;
        }
        
        .grid-footer {
          display: none;
          flex-wrap: wrap;

          &:has(.pagination > .page-item:nth-child(6)) {
            display: flex;
          }
        }

        .grid-pageinfo {
          margin-bottom: 5px;
        }
      }

      .grid-footer {
        margin-top: auto;
      }

      .table-no-data {
        padding: 6rem 2rem;
      }
      
      .table {
        color: var(--primary-color);
      }

      &:not(.futuristic-card-grid) {
        thead {
          display: none;
        }

        .grid-content {
          min-height: 300px;
        }
      }

      &.futuristic-card-grid {
        th {
          text-transform: uppercase;
          font-size: 14px;
        }

        td {
          height: 50px;
        }
      }
    }
  }

  // .custom-switch {
  //   .custom-control-label {
  //     cursor: pointer;
  //     padding-left: 3rem !important;
  //     padding-top: 0.67rem !important;

  //     &::before {
  //       height: 2.5rem !important;
  //       width: calc(4rem + 0.75rem) !important;
  //       border-radius: 5rem !important;
  //     }

  //     &::after {
  //       width: calc(2.5rem - 4px) !important;
  //       height: calc(2.5rem - 4px) !important;
  //       border-radius: calc(4rem - (2.5rem / 2)) !important;
  //     }
  //   }
  // }
  .form-number > input {
    padding: 7px 15px;
  }

  .auto-complete-select {
    .css-yk16xz-control {
      border-radius: 0.25rem;
    }
    
    .css-26l3qy-menu {

      .css-4ljt47-MenuList {

          .css-1n7v3ny-option {
            
          }

          .css-yt9ioa-option {
            color: #989c9e;
          }
      }
  }
  }
}

.hero {
  padding: 1rem 0 0;

  @include media-min-md {
      padding: 10rem 0 10rem;
  }

  .hero-image {
      display: flex;
      justify-content: center;
      
      img {
          max-width: 100%;
          aspect-ratio: 1;
          object-fit: cover;
          object-position: 100%;
          min-height: 400px;
          border: 1px solid var(--alternative-color);

          @include media-min-sm {
              min-height: 600px;
          }
      }
  }

  .hero-content {
    @include media-min-3xl {
        padding: 0 6rem 0 0;
    }

    > :not(.jumbo-text) > p {
      line-height: 1;
      font-size: 1.6rem;
      
      @include media-min-sm {
        line-height: 1.15;
          font-size: 1.9rem;
          margin-bottom: 2rem;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.preview-pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  max-height: 1200px;
  
  @include media-min-sm {
    min-height: 600px;
  }
  
  iframe {
    height: 100%;
    min-height: inherit;
    max-height: inherit;
  }
}

[data-env="uat"], [data-env="staging"] {
    [data-env-impose="true"] {
      position: relative;
    
      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-30deg);
        transform: translate(-50%, -50%) rotate(-15deg);
        color: #d94e35;
        font-size: 60px;
        border-top: 5px solid #d94e35;
        border-bottom: 5px solid #d94e35;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 0px 5px #c5c5db;
      }
      &.brand-logo::before {
        font-size: 30px;
        transform: translate(-50%, -50%) rotate(-10deg);
      }
    }
  
    &[data-env="staging"] {
      [data-env-impose="true"] {
        &::before {
          content: "TEST";
        }
      }
    }
  
    &[data-env="uat"] {
      [data-env-impose="true"] {
        &::before {
          content: "UAT";
        }
      }
    }
    
}

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"],
iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: medium; z-index: 2147483647;"] {
  display: none;
}