@import "./bootstrap.theme.scss";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";
@import "./mixins.scss";

:root {
    --primary: #{$primary};
    --primary-color: #{$primary};
    --primary-lighten-color: #{lighten($primary, 15)};
    --primary-lightest-color: #{lighten($primary, 30)};
    --primary-lightiest-color: #{lighten($primary, 60)};
    --primary-darken-color: #{darken($primary, 10)};
    --primary-darkest-color: #{darken($primary, 25)};
    --primary-darkiest-color: #{darken($primary, 35)};
    --primary-text-color: #fff;
    --secondary: #{$secondary};
    --secondary-color: #{$secondary};
    --secondary-lighten-color: #{lighten($secondary, 15)};
    --secondary-lighten-color: #{lighten($secondary, 15)};
    --secondary-lightest-color: #{lighten($secondary, 30)};
    --secondary-lightiest-color: #{lighten($secondary, 60)};
    --secondary-darken-color: #{darken($secondary, 10)};
    --secondary-darkest-color: #{darken($secondary, 25)};
    --secondary-darkiest-color: #{darken($secondary, 35)};
    --secondary-text-color: #fff;
    --alternative-color: #{$alternative};
    --success-color: #{$success};
    --info-color: #{$info};
    --warning-color: #{$warning};
    --danger-color: #{$danger};
    --font-family: 'Hind Siliguri', sans-serif;
    --secondary-font-family: 'Linear Grotesk', sans-serif;
    --fade-color: #d7d7d7;
    --white-color: #ffffff;
    --black-color: #0e101b;
    --paragraph-color: #666666;
    --transition: .5s;
    --font-size: 1.5rem;
    --text-highlight-color: #{$primary};
    --text-primary-color: #{$primary};
    --text-default-color: #0e101b;
    --bg-color: #fcfcfc;
}